import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import TopMenu from "views/TopMenu";
import Navbar from "views/Navbar";
import Footer from "views/Footer";
import Toolkit from "views/Toolkit";
import * as Sections from "views/Sections";
import SEO from "components/SEO";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import breakDownComponent from "utils/breakDownComponent";
import fileToComponentName from "utils/fileToComponentName";
import "../style/main.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query ToolkitQuery {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }) {
      nodes {
        frontmatter {
          brand
          anchor
          content
          copyright
          header
          imageFileName
          menuText
          services {
            header
            iconName
          }
          social {
            facebook
            github
            linkedin
            medium
            twitter
          }
          subheader
          title
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const ToolkitPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(
    nodes,
    "toolkit",
  );
  const { contentComponent: toolkitTopNode } = breakDownComponent(nodes, "ToolkitAbout");
  const pages = [
    {
      url: "/",
      title: "HOME",
    },
    {
      url: "/competence-center/",
      title: "Competence Center",
    },
    {
      url: "/toolkit/",
      title: "Toolkit",
    },
    {
      url: "/onboarding/",
      title: "Onboarding",
    },
  ];

  return (
    <>
      <SEO
        title="Onboarding - Home of Mobile Europeans (HOME)"
        keywords={keywords}
        description={description}
      />
      <TopMenu frontmatter={navBarNode.frontmatter} extraItems={pages} />
      <Toolkit className={"bg-light"} frontmatter={toolkitTopNode.frontmatter} />
      {/* <Navbar anchors={anchors} frontmatter={navBarNode.frontmatter} extraItems={pages} /> */}
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }, ind) => {
          const sectionComponentName = fileToComponentName(fileName);
          const SectionComponent = Sections[sectionComponentName];

          return SectionComponent ? (
            <SectionComponent
              key={sectionComponentName}
              className={ind % 2 === 1 ? "competence-section bg-light" : "competence-section"}
              frontmatter={frontmatter}
            />
          ) : null;
        })
      }
      <br />
      <br />
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

ToolkitPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ToolkitPage;
